import { Action } from '@reduxjs/toolkit'
import {
  Timestamp,
  DocumentReference,
  Firestore,
  collection,
  doc,
  CollectionReference,
  updateDoc,
  serverTimestamp,
  DocumentSnapshot,
} from 'firebase/firestore'
import { Epic } from 'redux-observable'
import { fromRef } from 'rxfire/firestore'
import {
  debounce,
  distinctUntilChanged,
  EMPTY,
  map,
  mapTo,
  mergeMap,
  Observable,
  skipWhile,
  switchMap,
  timer,
} from 'rxjs'
import type { RootState } from '../store'

interface Tick {
  tick: Timestamp
}

const MINIMUM_TIME_TO_TICK_IN_MS = 5000
const MAXIMUM_TIME_TO_TICK_IN_MS = 30300 //30 seconds and bit extra to be sure

export class TickerService {
  public readonly ticking$: Observable<true>

  private tickRef: DocumentReference<Tick>

  private tick$: Observable<DocumentSnapshot<Tick>>

  constructor(private db: Firestore) {
    this.tickRef = doc<Tick>(collection(this.db, 'tick') as CollectionReference<Tick>, 'tick')
    this.tick$ = fromRef(this.tickRef, {
      includeMetadataChanges: true,
    })
    this.ticking$ = this.tick$.pipe(
      debounce((tickDocument) => {
        const currentTick = tickDocument.get('tick', { serverTimestamps: 'estimate' }) as Tick['tick'] | undefined
        const waitTime = this.calcWaitTime(currentTick)
        console.log('waiting millis:', waitTime)
        return timer(waitTime)
      }),
      mergeMap(() => this.sendTick()),
      mapTo(true),
    )
  }

  private calcWaitTime(lastTick?: Timestamp): number {
    if (lastTick == null) {
      return MINIMUM_TIME_TO_TICK_IN_MS
    }
    const now = Timestamp.now()
    const diff = now.toMillis() - lastTick.toMillis()
    if (diff < 0) {
      return MINIMUM_TIME_TO_TICK_IN_MS
    }
    const timeUntilNextTick = MAXIMUM_TIME_TO_TICK_IN_MS - diff //30 seconds and bit extra to be sure
    return Math.max(timeUntilNextTick, MINIMUM_TIME_TO_TICK_IN_MS) //wait at least 5 seconds
  }

  private async sendTick(): Promise<void> {
    console.log('sending update tick')
    try {
      await updateDoc<Tick>(this.tickRef, { tick: serverTimestamp() })
      console.log('you sent the tick. you are the best👍')
    } catch (error) {
      console.log("couldn't send the tick, doesn't matter", error)
    }
  }
}

export const tickerEpic: Epic<Action, never, RootState, { firestore: Firestore }> = (_, state$, { firestore }) => {
  const ticker = new TickerService(firestore)
  return state$.pipe(
    map((state) => state.phrase.currentTarget),
    distinctUntilChanged(),
    switchMap((currentTarget) => {
      if (typeof currentTarget !== 'string') {
        return ticker.ticking$
      }
      return EMPTY
    }),
    skipWhile(() => true),
  )
}
