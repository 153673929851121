import { Action, createAsyncThunk } from '@reduxjs/toolkit'
import { collection, doc, CollectionReference, Firestore, setDoc, increment } from 'firebase/firestore'
import { Epic } from 'redux-observable'
import { collection as fromCollection } from 'rxfire/firestore'
import { distinctUntilChanged, switchMap, of, map, startWith } from 'rxjs'
import { setCurrentUpvoteAmount } from './phrase-slice'
import type { RootState } from '../store'
import { secureRandom } from '../util'

const NUMBER_OF_VOTE_SHARDS = 5

export interface Upvote {
  votes: number
  needsAggregation: true
}

const selectCurrentDocumentPath = (state: RootState): string | undefined => state.phrase.currentPhrase?.documentPath

function getUpvoteCollectionFromPath(firestore: Firestore, path: string): CollectionReference<Upvote> {
  const currentDocument = doc(firestore, path)
  return collection(currentDocument, 'upvotes') as CollectionReference<Upvote>
}

export const upvoteCounterEpic: Epic<
  Action,
  ReturnType<typeof setCurrentUpvoteAmount>,
  RootState,
  { firestore: Firestore }
> = (_, state$, { firestore }) => {
  return state$.pipe(
    map((state) => selectCurrentDocumentPath(state)),
    distinctUntilChanged(),
    switchMap((currentPath) => {
      if (!currentPath) {
        return of(0)
      }
      const upvoteCollection = getUpvoteCollectionFromPath(firestore, currentPath)
      return fromCollection(upvoteCollection).pipe(
        map((upvoteDocuments) =>
          upvoteDocuments.reduce((runningTotal, upvote) => runningTotal + upvote.data().votes, 0),
        ),
        startWith(0),
      )
    }),
    map((currentCount) => setCurrentUpvoteAmount(currentCount)),
  )
}

export const upvote = createAsyncThunk<void, void, { state: RootState; extra: { firestore: Firestore } }>(
  'phrase/upvote',
  async (_args, thunkApi) => {
    const currentPath = selectCurrentDocumentPath(thunkApi.getState())
    if (!currentPath) {
      return
    }
    const upvoteCollection = getUpvoteCollectionFromPath(thunkApi.extra.firestore, currentPath)
    const selectedShard = Math.floor(secureRandom() * NUMBER_OF_VOTE_SHARDS).toString()
    const upvoteDoc = doc(upvoteCollection, selectedShard)
    console.log('sending updoot', upvoteDoc.path)
    await setDoc(
      upvoteDoc,
      {
        votes: increment(1),
        needsAggregation: true,
      },
      { merge: true },
    )
  },
)
