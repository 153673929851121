import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'

export interface NoisyState {
  value: boolean
}

const initialState: NoisyState = {
  value: false,
}

export const noisySlice = createSlice({
  name: 'noisy',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    mute: (state) => {
      state.value = false
    },
    unmute: (state) => {
      state.value = true
    },
  },
})

export const { mute, unmute } = noisySlice.actions

export const selectNoisy = (state: RootState): boolean => state.noisy.value

export default noisySlice.reducer
