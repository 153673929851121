import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ContentCopy, ArrowBack, Done } from '@mui/icons-material'
import { Fab, Tooltip } from '@mui/material'
import copy from 'copy-to-clipboard'
import debounce from 'lodash/debounce'
import { selectCurrentPhrase, targetId } from './phrase/phrase-slice'
import { useAppDispatch, useAppSelector } from './hooks'

const TIME_TO_SHOW_COPIED_ICON_IN_MS = 2000

function StaticMenu(): JSX.Element {
  // ensure target set in state when this component loaded
  const { phraseId } = useParams<{ phraseId: string }>()
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(targetId(phraseId))
  }, [phraseId, dispatch])
  // manage the text in copy button tooltip
  const [currentlyShowingCopied, setCurrentlyShowingCopied] = useState(false)
  // debounced function that will reset the tooltip text after a timeout
  const resetCopyTooltipInABit = useMemo(
    () => debounce(() => setCurrentlyShowingCopied(false), TIME_TO_SHOW_COPIED_ICON_IN_MS),
    [setCurrentlyShowingCopied],
  )
  // if the menu is gone, give up
  useEffect(() => () => resetCopyTooltipInABit.cancel(), [resetCopyTooltipInABit])
  const phrase = useAppSelector(selectCurrentPhrase)
  const copyClick = useCallback(() => {
    if (phrase) {
      copy(`${phrase.words} https://deeperth.ink/${phrase.id}`)
      setCurrentlyShowingCopied(true)
      resetCopyTooltipInABit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phrase?.id, phrase?.words, setCurrentlyShowingCopied, resetCopyTooltipInABit])
  return (
    <>
      <Tooltip title={currentlyShowingCopied ? 'Copied!' : 'Copy'} placement="left">
        <Fab aria-label="Copy" onClick={copyClick}>
          {currentlyShowingCopied ? <Done /> : <ContentCopy />}
        </Fab>
      </Tooltip>
      <Tooltip title="Back" placement="left">
        <Fab component={Link} to="/">
          <ArrowBack />
        </Fab>
      </Tooltip>
    </>
  )
}

export default StaticMenu
