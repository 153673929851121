import seedrandom from 'seedrandom'

export function seededSample<T>(collection: T[], seed: string | undefined): T {
  const rand = seedrandom(seed).quick()
  const index = Math.floor(collection.length * rand)
  return collection[index]
}
export function seededInRange(lower: number, upper: number, seed: string | undefined): number {
  const rand = seedrandom(seed).quick()
  return lower + rand * (upper - lower)
}

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const MAXIMUM_SAFE_32BIT_INT = 2 ** 32 - 1
export function secureRandom(): number {
  // Divide a random UInt32 by the maximum value (2^32 -1) to get a result between 0 and 1
  return window.crypto.getRandomValues(new Uint32Array(1))[0] / MAXIMUM_SAFE_32BIT_INT
}

/** gets random element from array. DO NOT USE ON POSSIBLY EMPTY ARRAY */
export function sample<T>(array: readonly T[]): T {
  if (array.length === 0) {
    throw new Error('cannot sample empty array')
  }
  return array[Math.floor(secureRandom() * array.length)]
}
