import { ReportHandler } from 'web-vitals'

async function setupWebVitals(onPerfEntry: ReportHandler): Promise<void> {
  const { getCLS, getFID, getFCP, getLCP, getTTFB } = await import('web-vitals')
  getCLS(onPerfEntry)
  getFID(onPerfEntry)
  getFCP(onPerfEntry)
  getLCP(onPerfEntry)
  getTTFB(onPerfEntry)
}
const reportWebVitals = (onPerfEntry?: ReportHandler): void => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    void setupWebVitals(onPerfEntry)
  }
}

export default reportWebVitals
