import React, { useCallback, useEffect } from 'react'
import { VolumeOff, VolumeUp } from '@mui/icons-material'
import { Fab, Tooltip } from '@mui/material'
import { MusicService } from './music'
import { useAppDispatch, useAppSelector } from '../hooks'
import { mute, selectNoisy, unmute } from './noisy-slice'

function NoiseButton(): JSX.Element {
  const noisy = useAppSelector(selectNoisy)
  const dispatch = useAppDispatch()
  const music = MusicService.instance
  useEffect(() => {
    if (noisy && music.audioAllowed()) {
      void music.play() // discard the promise, apparently
    }
    return () => music.stop()
  }, [noisy, music])
  const buttonClick = useCallback(() => dispatch(noisy ? mute() : unmute()), [dispatch, noisy])
  return (
    <Tooltip title={noisy ? 'Mute' : 'Unmute'} placement="left">
      <Fab onClick={buttonClick}>{noisy ? <VolumeUp /> : <VolumeOff />}</Fab>
    </Tooltip>
  )
}

export default NoiseButton
