import React, { useEffect } from 'react'
import { Typography } from '@mui/material'
import { createSelector } from '@reduxjs/toolkit'
import { useAppSelector } from './hooks'
import { selectCurrentPhrase } from './phrase/phrase-slice'
import { seededSample, seededInRange } from './util'
import { selectNoisy } from './noisy/noisy-slice'

const phraseWordsSelector = createSelector(selectCurrentPhrase, (phrase) => phrase?.words)
const FONTS = [
  'Baloo',
  'Crimson Text',
  'Gidugu',
  'Griffy',
  'Indie Flower',
  'Raleway',
  'Ranga',
  'Roboto',
  'Supermercado One',
]

const SMALLEST_DEGREES = 0
const LARGEST_DEGREES = 360

const SMALLEST_PITCH = 0.5
const LARGEST_PITCH = 1.2

const SMALLEST_RATE = 0.6
const LARGEST_RATE = 0.7

function Phrase(): JSX.Element {
  const phraseWords = useAppSelector(phraseWordsSelector)
  const font = `${seededSample(FONTS, phraseWords)}, sans-serif`
  const noisy = useAppSelector(selectNoisy)

  useEffect(() => {
    document.body.style.background = `hsl(${seededInRange(SMALLEST_DEGREES, LARGEST_DEGREES, phraseWords)}, 50%, 90%)`
  }, [phraseWords])

  useEffect(() => {
    if (noisy && phraseWords) {
      const msg = new SpeechSynthesisUtterance()
      msg.voice = seededSample(
        speechSynthesis.getVoices().filter((voice) => voice.lang.startsWith('en-')),
        phraseWords,
      )
      msg.text = phraseWords
      msg.pitch = seededInRange(SMALLEST_PITCH, LARGEST_PITCH, phraseWords)
      msg.rate = seededInRange(SMALLEST_RATE, LARGEST_RATE, phraseWords)
      speechSynthesis.speak(msg)
      return () => speechSynthesis.cancel()
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function -- we need a noop here for type consistency
    return () => {}
  }, [noisy, phraseWords])

  return (
    <Typography fontFamily={font} fontSize="36px">
      {phraseWords}
    </Typography>
  )
}

export default Phrase
