import random from 'lodash/random'
import { Frequency, getContext, JCReverb, Loop, Noise, PolySynth, start, Time, Transport } from 'tone'
import { sample } from '../util'

const TONE_LENGTH_RANDOM_DURATION_IN_SECS = 0.3
const NOISE_VOLUME_RAMP_MINIMUM = -40
const NOISE_VOLUME_RAMP_MAXIMUM = -20

export class MusicService {
  pianoLoop: Loop

  noiseLoop: Loop

  noise: Noise

  constructor() {
    const NOTES = ['A', 'B', 'C', 'D', 'E', 'F', 'G'] as const
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const OCTIVES = [3, 4] as const
    const reverb = new JCReverb().toDestination()
    const piano = new PolySynth()
      .set({
        volume: -25,
        envelope: {
          attackCurve: 'sine',
          releaseCurve: 'sine',
          attack: 0.4,
          decay: 0.6,
          sustain: 0.1,
          release: 1,
        },
        oscillator: {
          type: 'sine4',
        },
        portamento: 0.05,
      })
      .connect(reverb)

    this.pianoLoop = new Loop((_time) => {
      const toneLength =
        Time('4n').toSeconds() + random(-TONE_LENGTH_RANDOM_DURATION_IN_SECS, TONE_LENGTH_RANDOM_DURATION_IN_SECS)
      if (toneLength > 0) {
        piano.triggerAttackRelease(Frequency(`${sample(NOTES)}${sample(OCTIVES)}`).toFrequency(), toneLength)
      }
    }, '4n')
    this.pianoLoop.humanize = true
    this.pianoLoop.probability = 0.7
    this.noise = new Noise('brown').toDestination()
    this.noise.volume.value = NOISE_VOLUME_RAMP_MINIMUM
    this.noiseLoop = new Loop(() => {
      this.noise.volume.rampTo(
        random(NOISE_VOLUME_RAMP_MINIMUM, NOISE_VOLUME_RAMP_MAXIMUM),
        Time('2m').toSeconds() - Time('8n').toSeconds(),
      )
    }, '2m')
  }

  audioAllowed(): boolean {
    return getContext().rawContext.state === 'running'
  }

  async play(): Promise<void> {
    // debugger
    await this.resume()
    this.noise.start()
    if (this.noiseLoop.state === 'stopped') {
      this.noiseLoop.start(0)
    }
    if (this.pianoLoop.state === 'stopped') {
      this.pianoLoop.start(0)
    }
    Transport.start()
  }

  async resume(): Promise<void> {
    await start()
  }

  stop(): void {
    this.noise.stop()
    Transport.stop()
  }

  static _instance: MusicService | null = null

  static get instance(): MusicService {
    if (!this._instance) {
      this._instance = new MusicService()
    }
    return this._instance
  }
}
