import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Box, Stack, useTheme } from '@mui/material'
import StaticMenu from './StaticMenu'
import RotatingMenu from './RotatingMenu'
import Upvote from './Upvote'
import NoiseButton from './noisy/NoiseButton'
import Phrase from './Phrase'

function App(): JSX.Element {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexBasis: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          padding: `0 10vw`,
        }}
      >
        <Phrase />
      </Box>
      <Stack
        spacing={2}
        sx={{
          position: 'fixed',
          bottom: theme.spacing(2),
          right: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        <Switch>
          <Route path="/:phraseId">
            <StaticMenu />
          </Route>
          <Route path="/">
            <RotatingMenu />
          </Route>
        </Switch>
        <Upvote />
        <NoiseButton />
      </Stack>
    </Box>
  )
}

export default App
