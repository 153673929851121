import { Action } from '@reduxjs/toolkit'
import { DocumentReference, collection, doc, CollectionReference, Firestore, Timestamp } from 'firebase/firestore'
import { Epic } from 'redux-observable'
import { docData, fromRef } from 'rxfire/firestore'
import { distinctUntilChanged, switchMap, of, distinctUntilKeyChanged, pluck, map } from 'rxjs'
import { setPhrase, notFound } from './phrase-slice'
import type { RootState } from '../store'

export interface PhraseDocument {
  id: string
  words: string
  createdAt: Timestamp
  origin: DocumentReference[]
}

export interface CurrentPhrase {
  id: DocumentReference<PhraseDocument>
}

export const phraseKnowerEpic: Epic<
  Action,
  ReturnType<typeof setPhrase | typeof notFound>,
  RootState,
  { firestore: Firestore }
> = (_, state$, { firestore }) => {
  const tickRef = doc<CurrentPhrase>(
    collection(firestore, 'tick') as CollectionReference<CurrentPhrase>,
    'currentPhrase',
  )
  const phraseCollection = collection(firestore, 'phrases') as CollectionReference<PhraseDocument>
  const rotatingPhraseDoc$ = docData(tickRef).pipe(pluck('id'))
  return state$.pipe(
    map((state) => state.phrase.currentTarget),
    distinctUntilChanged(),
    switchMap((currentTarget) => {
      if (typeof currentTarget !== 'string') {
        return rotatingPhraseDoc$
      }
      return of(doc(phraseCollection, currentTarget))
    }),
    distinctUntilKeyChanged('id'),
    switchMap((phraseDoc) => fromRef(phraseDoc)),
    map((snapshot) => {
      const data = snapshot.data()
      if (!data) {
        return notFound()
      }
      return setPhrase({
        id: snapshot.id,
        words: data.words,
        createdAt: data.createdAt.toJSON(),
        origin: data.origin.map((reference) => reference.id),
        upvoteCount: 0,
        documentPath: snapshot.ref.path,
      })
    }),
  )
}
