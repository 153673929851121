import { Timestamp } from 'firebase/firestore'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

type PhraseTarget = string | null

export interface PhraseCurrentKnown {
  id: string
  words: string
  origin: string[]
  createdAt: ReturnType<Timestamp['toJSON']>
  documentPath: string
  upvoteCount: number
}
export interface PhraseState {
  currentPhrase: PhraseCurrentKnown | null
  status: 'loading' | 'loaded' | 'notFound'
  currentTarget: PhraseTarget
}

const initialState: PhraseState = {
  currentPhrase: null,
  status: 'loading',
  currentTarget: null,
}

export const phraseSlice = createSlice({
  name: 'phrase',
  initialState,
  reducers: {
    setPhrase: (state, action: PayloadAction<PhraseCurrentKnown>) => {
      state.status = 'loaded'
      state.currentPhrase = action.payload
    },
    rotate: (state) => {
      if (state.currentTarget !== null) {
        state.currentTarget = null
        state.status = 'loading'
      }
    },
    targetId: (state, action: PayloadAction<string>) => {
      if (state.currentTarget === null) {
        state.currentTarget = action.payload
        if (state.currentPhrase?.id !== action.payload) {
          state.status = 'loading'
        }
      }
    },
    setCurrentUpvoteAmount: (state, action: PayloadAction<number>) => {
      if (state.currentPhrase) {
        state.currentPhrase.upvoteCount = action.payload
      }
    },
    notFound(state) {
      state.status = 'notFound'
      state.currentPhrase = null
    },
  },
})

export const { rotate, setPhrase, targetId, setCurrentUpvoteAmount, notFound } = phraseSlice.actions

export const selectCurrentPhrase = (state: RootState): PhraseCurrentKnown | null => state.phrase.currentPhrase

export default phraseSlice.reducer
