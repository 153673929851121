import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Fab, Tooltip } from '@mui/material'
import { Share } from '@mui/icons-material'
import { createSelector } from '@reduxjs/toolkit'
import { rotate, selectCurrentPhrase } from './phrase/phrase-slice'
import { useAppDispatch, useAppSelector } from './hooks'

const phraseIdSelector = createSelector(selectCurrentPhrase, (phrase) => phrase?.id)

function RotatingMenu(): JSX.Element {
  const phraseId = useAppSelector(phraseIdSelector)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(rotate())
  }, [dispatch])
  return (
    <Tooltip title="Share" placement="left">
      <Fab disabled={phraseId == null} component={Link} to={`/${phraseId || ''}`}>
        <Share />
      </Fab>
    </Tooltip>
  )
}

export default RotatingMenu
