import { Badge, Fab, Tooltip } from '@mui/material'
import { Favorite } from '@mui/icons-material'
import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from './hooks'
import { selectCurrentPhrase } from './phrase/phrase-slice'
import { upvote } from './phrase/phrase-upvotes'

function Upvote(): JSX.Element {
  const dispatch = useAppDispatch()
  const phrase = useAppSelector(selectCurrentPhrase)
  const upvotes = phrase?.upvoteCount || 0

  const upvoteClick = useCallback(() => dispatch(upvote()), [dispatch])
  return (
    <Badge
      showZero
      badgeContent={upvotes}
      color="primary"
      overlap="circular"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Tooltip title="Like" placement="left">
        <Fab onClick={upvoteClick}>
          <Favorite />
        </Fab>
      </Tooltip>
    </Badge>
  )
}

export default Upvote
