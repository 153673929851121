import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { combineEpics, createEpicMiddleware } from 'redux-observable'
import { Firestore } from 'firebase/firestore'
import noisySliceReducer from './noisy/noisy-slice'
import phraseSliceReducer from './phrase/phrase-slice'
import { phraseKnowerEpic } from './phrase/phrase'
import { firestore } from './firebase/client'
import { upvoteCounterEpic } from './phrase/phrase-upvotes'
import { tickerEpic } from './phrase/ticker'

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- we cast to RootState later. otherwise this is circular
const epicMiddleware = createEpicMiddleware<Action, Action, any, { firestore: Firestore }>({
  dependencies: { firestore },
})

export const store = configureStore({
  reducer: {
    noisy: noisySliceReducer,
    phrase: phraseSliceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { firestore },
      },
      // eslint-disable-next-line unicorn/prefer-spread
    }).concat(epicMiddleware),
})
epicMiddleware.run(
  combineEpics<Action, Action, RootState, { firestore: Firestore }>(phraseKnowerEpic, upvoteCounterEpic, tickerEpic),
)
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
